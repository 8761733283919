<template
  ><div>
    <Navbar />
    <div class="text-white mt-3">
      <h3>Projets sur lesquels j'ai travaillé :</h3>
    </div>
    <Projets />
    <Footer />
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";

import Projets from "@/components/portfolio/Projets.vue";
import Footer from "@/components/Footer.vue";

export default {
  components: {
    Navbar,
    Projets,
    Footer
  },
  data() {
    return {};
  },
  methods: {}
};
</script>

<style></style>
